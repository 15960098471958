<template>
  <div class="featureSlider d-flex flex-column justify-content-start">
    <div class="featureSlider__header--no-padding pb-0 mx-auto">
      <img :src="getSiteDetails.login_logo"/>
    </div>
    <div class="VueCarousel featureSlider__carousel">
      <slick ref="slick" :options="slickOptions">
        <div class="auth-features-container d-flex flex-column align-items-center text-center justify-content-between">
          <div class="auth-features">
            <div class="auth-features__heading">
              <h2>New Feature: Deep Links</h2>
              <p> Create links to navigate users to the Google Play Store or Apple Store and download the intended app. Play videos and songs directly from an appropriate app to increase app installations. </p>
            </div>
            <div class="auth-features__thumbnail">
              <img src="/assets/img/login/deep_linking.png"/>
            </div>
          </div>
        </div>
        <div class="auth-features-container d-flex flex-column align-items-center text-center justify-content-between">
          <div class="auth-features">
            <div class="auth-features__heading">
              <h2>New Feature: Traffic Routing Rules</h2>
              <p> Traffic routing rules empower you to target specific audiences, audience segmentation, and the ability to have multiple URL destinations. Get total control of your links, all intending to boost your conversions.

              </p>
            </div>
            <div class="auth-features__thumbnail">
              <img
                src="/assets/img/login/traffic_routing.png"/>
            </div>
          </div>
        </div>
        <div class="auth-features-container d-flex flex-column align-items-center text-center justify-content-between">
          <div class="auth-features">
            <div class="auth-features__heading">
              <h2>New Feature: A/B Testing</h2>
              <p> Webpage A/B testing gives you insight on which page performs better based on conversion rates, session times, and bounce rate. Create high converting marketing campaigns by A/B testing your landing pages. </p>
            </div>
            <div class="auth-features__thumbnail">
              <img
                src="/assets/img/login/ab-testing.png"/>
            </div>
          </div>
        </div>
      </slick>
    </div>
  </div>
</template>
<script>

export default {
  name: 'Carousel',
  data () {
    return {
      slickOptions: {
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        touchMove: true,
        autoplay: true,
        responsive: [
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      }
    }
  }
}
</script>
